import { useEffect, useState } from "react"
import { Section } from "src/components/Grid"
import Loader from "src/components/Loader"
import Layout from "src/layout"

const NotFoundPage: GatsbyPage = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Layout title={loaded ? "404" : undefined}>
      {loaded ? (
        <Section>
          <h1>404</h1>
          <p>Page introuvable</p>
        </Section>
      ) : (
        <Loader />
      )}
    </Layout>
  )
}

export default NotFoundPage
